body.ui_dark {
    background-color: black;
    color: $background_color_4;

    .card-body {
        background-color: black;

        .tweet-text {
             color: white;
                }
    }

    .modal-dialog .modal-header{
        background-color: black;
    }
    .modal-body {
        background-color: black;
    }
    .modal-footer {
        background-color: black;
    }

    .bg-light {
        background-color: #141414 !important;
        color: white !important;

        .btn-dark {
            background-color: gray !important;
            }
    }

    .text-muted {
        color: rgb(207, 207, 207) !important;
    }

    /** checkout stuff */
    .col-12.col-lg-4.card.mb-4.shadow-sm.suggested-plan {
        background-color: black;
        border: solid 1px white;
        :hover {
            background-color: #87c097;
            }
        }

    .col-12.col-lg-4.card.mb-4.shadow-sm {
        background-color: black;
        border: solid 1px white;
        :hover {
            background-color: #87c097;
        }
    }
}