// Public facing pages
// - signup, public home, etc

body {
  background-color: white;
  color: black;
}
.signin {

  .signin-header {
    text-align: center;
  }

  .loader {
    margin: 4rem 0;
  }
}